<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>商戶列表</el-breadcrumb-item>
                <el-breadcrumb-item @click="goBack"
                                    class="go-back-font">專案列表</el-breadcrumb-item>
                <el-breadcrumb-item>專案詳情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="title">{{projectInfo.name}}</div>
            <el-tabs v-model="activeName"
                     type="card"
                     @tab-click="changeTabs">
                <el-tab-pane label="Grafana監控"
                             name="Grafana監控">
                    <el-row class="add-btn">
                        <el-col>
                            <el-button type="primary"
                                       @click="toChart()">圖表管理</el-button>
                        </el-col>
                    </el-row>
                    <template v-if="chartInfo.length>0">
                        <el-row v-for=" (chart,index) in chartInfo"
                                :key="index">
                            <el-col>
                                <div class="chart-title">{{chart.name}}</div>
                                <iframe width="100%"
                                        height="500px"
                                        :src="chart.url+'&project_id='+projectId+'&token='+token"
                                        title="W3Schools Free Online Web Tutorials"></iframe>
                            </el-col>
                        </el-row>
                    </template>
                    <template v-else>
                        <div class="no-result">暫無圖表</div>
                    </template>

                </el-tab-pane>
                <el-tab-pane label="專案網域列表"
                             name="專案網域列表">
                    <el-row class="add-btn">
                        <el-col>
                            <el-button type="danger"
                                       @click="deleteDomain()">刪除網域</el-button>
                        </el-col>
                    </el-row>
                    <TableTemplate :colums="colums"
                                   v-loading="listLoading"
                                   :data="tableData"
                                   :selectionShow="true"
                                   @selection-change="selectionChange"
                                   :paginationShow="true"
                                   :pageIndex="pageIndex"
                                   :pageCount="pageCount"
                                   @change-page="changePage">
                        <!-- 自動續費 -->
                        <template v-slot:is_renew_auto="data">
                            <div v-if="data.data.is_renew_auto=='Y'">是</div>
                            <div v-else>否</div>
                        </template>
                        <!-- DNS指向 -->
                        <template v-slot:nameservers="data">
                            <el-tooltip class="item"
                                        effect="dark"
                                        placement="top">
                                <template #content> <template v-for="(server,idx) in data.data.nameservers"
                                              :key="idx">
                                        {{server}}
                                        <br>
                                    </template>
                                </template>
                                <div class="tool-tip-text"
                                     v-html="nameSeverChange(data.data.nameservers)"></div>
                            </el-tooltip>
                        </template>
                        <!-- 分享給-->
                        <template v-slot:share_list="data">
                            <div :key="index"
                                 v-for="(share,index) in data.data.share_list">
                                {{share.share_id}}
                            </div>
                        </template>
                        <!-- tag -->
                        <template v-slot:tags="data">
                            <el-tag :key="index"
                                    v-for="(tag,index) in data.data.tags"
                                    :disable-transitions="false">
                                {{tag.tag}}
                            </el-tag>
                        </template>
                    </TableTemplate>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import TableTemplate from '../../../../components/TableTemplate.vue'
import { showProjectList, projectDomainList, deleteProjectDomain } from '../../../../api/index'
import { getCookie } from '../../../../utils/cookie'
export default {
    components: {
        TableTemplate,
    },
    data() {
        return {
            userId: '',
            projectId: '',
            token: getCookie('__tk').replace('Bearer', '').trim(),
            activeName: 'Grafana監控',
            projectInfo: [],
            chartInfo: [],
            colums: [
                {
                    name: '網域名稱',
                    prop: 'domain',
                },
                {
                    name: '來源註記',
                    prop: 'remark',
                },
                {
                    name: '建立時間',
                    prop: 'domain_created_at',
                },
                {
                    name: '到期時間',
                    prop: 'domain_expires_at',
                },
                {
                    name: '自動續費',
                    type: 'slot',
                    attribute: { slotName: 'is_renew_auto' },
                },
                {
                    name: '域名狀態',
                    prop: 'status',
                },
                {
                    name: '最後更新時間',
                    prop: 'updated_at',
                },
                {
                    name: 'DNS指向',
                    type: 'slot',
                    attribute: { slotName: 'nameservers' },
                },
                {
                    name: '分享來源',
                    prop: 'share_from',
                },
                {
                    name: '分享給',
                    type: 'slot',
                    attribute: { slotName: 'share_list' },
                },
                {
                    name: 'TAG',
                    type: 'slot',
                    attribute: { slotName: 'tags' },
                },
            ],
            tableData: [],
            pageIndex: 1,
            pageCount: 0,
            listLoading: false,
            selectionId: [],
        }
    },
    mounted() {
        if (typeof this.$route.query.id == 'undefined') {
            this.$router.push({ path: 'commercialList' })
        } else {
            this.userId = this.$route.query.id
            this.projectId = this.$route.query.projectId
            this.getData()
        }
    },
    methods: {
        getData() {
            showProjectList({
                id: this.projectId,
            })
                .then((res) => {
                    if (res.code == 300) {
                        this.projectInfo = res.data.project
                        this.chartInfo = res.data.charts
                    }
                    if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                    this.listLoading = false
                })
                .catch((err) => {
                    console.log(err)
                    this.listLoading = false
                })
        },
        getProjectDomain() {
            this.listLoading = true
            projectDomainList({
                project_id: this.projectId,
                page: this.pageIndex,
            })
                .then((res) => {
                    if (res.code == 300) {
                        this.tableData = res.data.data_list
                        this.pageCount = res.data.total_page
                    }
                    if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                    this.listLoading = false
                })
                .catch((err) => {
                    console.log(err)
                    this.listLoading = false
                })
        },
        changeTabs(tab) {
            if (tab.props.label === '專案網域列表') {
                this.getProjectDomain()
            }
        },
        toChart() {
            this.$router.push({
                path: 'chartList',
                query: { id: this.userId, projectId: this.projectId },
            })
        },
        goBack() {
            this.$router.push({
                path: 'projectList',
                query: { id: this.userId },
            })
        },
        changePage(val) {
            this.pageIndex = val
            this.getData()
        },
        selectionChange(val) {
            this.selectionId = []
            val.forEach((element) => {
                this.selectionId.push(element.id)
            })
        },
        deleteDomain() {
            if (this.selectionId.length < 1) {
                this.$message.error('請勾選要刪除的網域')
                return
            }
            this.$confirm('确定要删除吗？', '提示', {
                type: 'warning',
            })
                .then(() => {
                    deleteProjectDomain({ id: this.selectionId }).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('删除成功')
                            this.getProjectDomain()
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        //dns指向更改
        nameSeverChange(array) {
            let a = array || []
            return a.join('<br>')
        },
    },
}
</script>

<style lang="scss">
.go-back-font {
    .el-breadcrumb__inner {
        font-weight: 700;
        color: #303133;
    }
    .el-breadcrumb__inner:hover {
        color: #409eff;
        cursor: pointer;
    }
}
</style>
<style lang="scss" scoped>
.title {
    text-align: center;
    font-size: 22px;
    padding-bottom: 15px;
    font-weight: bold;
}
.add-btn {
    padding-bottom: 15px;
}
.chart-title {
    padding: 10px 0px;
    font-size: 18px;
}
.no-result {
    text-align: center;
    color: #999;
}
</style>